<template>
  <div class="resource">
    <div class="resource_center">
      <header>
        <p>课程上传管理</p>
        <el-button type="primary" @click="uploadresource" size="mini">
          <i class="btns_icon"></i>
          上传课程
        </el-button>
      </header>
      <nav>
        <!-- label-width="80px" -->
        <el-form ref="resourceRef" :model="resourceForm" :inline="true">
          <el-form-item label="课程名称" prop="courseName">
            <el-input v-model="resourceForm.courseName" @change="querylist" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="学科类型" prop="subjectTypeId">
            <el-select v-model="resourceForm.subjectTypeId" @change="changeshuject" size="mini" clearable>
              <el-option v-for="item in subjectypedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科" prop="subjectId">
            <el-select v-model="resourceForm.subjectId" size="mini" clearable>
              <el-option v-for="item in subjectdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学段" prop="periodId">
            <el-select v-model="resourceForm.periodId" @change="changeperiod" size="mini" clearable>
              <el-option v-for="item in perioddata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级" prop="gradeId">
            <el-select v-model="resourceForm.gradeId" size="mini" clearable>
              <el-option v-for="item in gradedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-form-item prop="startTime" style="width:150px">
              <!-- <el-date-picker
                type="datetime"
                placeholder="选择日期时间"
                v-model="resourceForm.startTime"
                size="mini"
                clearable
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker> -->
              <el-date-picker type="date" placeholder="选择日期" v-model="resourceForm.startTime" size="mini" value-format="yyyy-MM-dd" clearable></el-date-picker>
            </el-form-item>
            <el-form-item prop="endTime" style="width:150px">
              <el-date-picker type="date" placeholder="选择日期" v-model="resourceForm.endTime" size="mini" value-format="yyyy-MM-dd" clearable></el-date-picker>
            </el-form-item>
          </el-form-item>
          <el-form-item label="审核状态" prop="audit">
            <el-select v-model="resourceForm.audit" placeholder="请选择" size="mini" clearable>
              <el-option v-for="item in auditdata" :key="item.id" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="primary" @click="querylist" size="mini">查询</el-button>
            <el-button type="primary" @click="resetfrom('resourceRef')" size="mini">重置</el-button>
          </el-form-item>-->
        </el-form>
        <div class="nav_btns">
          <el-button type="primary" @click="querylist" size="mini">查询</el-button>
          <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
        </div>
      </nav>
      <aside>
        <el-button type="primary" @click="submitdata(null)" size="mini">批量提交审核</el-button>
        <el-button type="danger" @click="deldata(null)" size="mini">批量删除课程</el-button>
      </aside>
      <main>
        <!-- border边框 -->
        <el-table :data="resTabDatas" style="width: 100%" @selection-change="handleSelectionChange" height="475" stripe>
          <el-table-column type="selection" width="50" align="center" fixed="left"></el-table-column>
          <el-table-column type="index" width="50" align="center" fixed="left" label="序号" :index="computeTableIndex"></el-table-column>
          <el-table-column prop="title" label="课程标题" width="150" fixed="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="periodName" label="学段" width="120" show-overflow-tooltip></el-table-column>

          <el-table-column prop="gradeName" label="年级" width="125" show-overflow-tooltip></el-table-column>
          <el-table-column prop="subjectType" label="学科类型" width="120"></el-table-column>

          <el-table-column prop="subjectName" label="学科" width="120" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="resourceType" label="资源类型" width="120"></el-table-column> -->
          <el-table-column prop="auditStatus" label="审核状态" width="120"></el-table-column>
          <el-table-column prop="auditMessage" label="审核意见" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="提交审核" placement="top">
                <el-button
                  size="mini"
                  circle
                  @click="submitdata(scope.row.id)"
                  :disabled="scope.row.auditStatus=='提交待审核'||scope.row.auditStatus=='审核通过'"
                >
                  <i class="btn_sumbit"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="编辑课程" placement="top">
                <el-button
                  size="mini"
                  circle
                  @click="editdata(scope.row)"
                  :disabled="scope.row.auditStatus=='提交待审核'||scope.row.auditStatus=='审核通过'"
                >
                  <i class="btn_edit"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="查看课程" placement="top" v-if="scope.row.auditStatus=='审核通过'">
                <el-button size="mini" circle @click="viewdata(scope.row,1)">
                  <i class="btn_view"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="查看课程" placement="top" v-else>
                <el-button size="mini" circle @click="viewdata(scope.row,2)">
                  <i class="btn_view"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除课程" placement="top">
                <el-button
                  size="mini"
                  circle
                  @click="deldata(scope.row.id)"
                  :disabled="scope.row.auditStatus=='提交待审核'||scope.row.auditStatus=='审核通过'"
                >
                  <i class="btn_del"></i>
                </el-button>
              </el-tooltip>
              <!-- <el-button type="text" size="small" @click="submitdata(scope.row.id)">提交审核</el-button> -->
              <!-- <el-button type="text" size="small" @click="editdata(scope.row)">编辑课程</el-button> -->
              <!-- <el-button type="text" size="small" @click="viewdata(scope.row)">查看课程</el-button> -->
              <!-- <el-button type="text" size="small" @click="deldata(scope.row.id)">删除课程</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="resTabDatas"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="curpage"
          :page-sizes="[ 10, 20, 30, 40 ,50]"
          :page-size="pageSize"
          layout="total,sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </main>

      <!-- 承诺说明 -->
      <el-dialog title="资源知识产权承诺说明" :visible.sync="agreementDialog" width="30%" top="10%" class="agreeStyle" center>
        <!-- <p>{{datagreement}}</p> -->
        <div style="border:1px solid #EBEBEB;padding:15px 50px 15px">
          <p>1. 本人保证所有提交的内容不涉及未经授权的他人版权和著作权，承诺因引用他人作品而引发的版权和著作积纠纷由本人负责处理。</p>
          <p>2. 本人保证所提交内容行合国家法律法规规定，并承担因所提交内容不合法所引起的法律责任。</p>
          <p>3. 本人同意接受本次资源上传活动所组织的初审和专家评审，同意授程本次活动组织者以非营利的方式通过朝阳区教育资源公共服务体系等方式向社会公开推荐。</p>
        </div>

        <el-checkbox v-model="agreementDo" class="aCheck" style="margin-top:30px; font-weight:600;font-size:14px">我已阅读并同意资源支持产权承诺说明</el-checkbox>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sureagreement" size="mini">确 定</el-button>
          <el-button @click="cancleagreement" size="mini">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { agreement as agereedata } from "@/static/agreement.js";
export default {
  data() {
    return {
      // 课程审核列表
      resourceForm: {
        courseName: null,
        // resourceType: null,
        subjectTypeId: null,
        subjectId: null,
        periodId: null,
        gradeId: null,
        startTime: null,
        endTime: null,
        audit: null,
      },
      // 资源类型
      // restypedata: null,
      //学科类型
      subjectypedata: null,
      // 学科
      subjectdata: null,
      // 学段
      perioddata: null,
      // 年级
      gradedata: null,
      //审核状态
      auditdata: null,

      // 表格数据
      resTabData: null,
      // 多选的数据
      multipleSelection: null,

      // 分页
      curpage: 1, //当前页数
      pageSize: 10, //当前条数
      total: null, //总数

      // 弹框
      agreementDialog: false,
      agreementDo: false,
      datagreement: null,
    };
  },
  created() {
    this.datagreement = agereedata; //协议内容
    // this.queryResourceType(); // 获取资源类型
    this.querySubjectType(); //获取课程类型
    this.querySubject(0); //获取学科信息
    this.queryPeriod(); //获取学段
    this.queryGrade(0); //获取年级信息
    this.querystatus(); //获取审核状态
    // 获取课程列表
    this.queryCourselists();
  },
  methods: {
    // 查询课程列表
    async queryCourselists() {
      console.log(this.resourceForm, "resourceForm");
      const { data, status } = await this.$Http.queryCourselists({
        // courseName: courseName, /课程名称
        // resourceType: resourceType, //课程类型
        // subjectTypeId: subjectTypeId, //课程类型ID
        // subjectId: subjectId, //学科ID
        // periodId: periodId, //学段ID
        // gradeId: gradeId, //年级ID
        // startTime: startTime, //审核开始时间
        // endTime:endTime,//创建结束时间
        // audit: audit, //审核状态
        ...this.resourceForm,
        courseType: 1, //课程类型(1-课程，2-资料)
        courseIdList: null,
        sortId: 1, //排序Id
        sortType: 1, //排序类型
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
      });
      if (status == 200) {
        // console.log(data, status, "获取课程列表");
        this.resTabData = data;
      }
    },
    // 获取资源类型
    // async queryResourceType() {
    //   await this.$Http.queryResourceType().then((res) => {
    //     console.log(res, "获取资源类型");
    //     if (res.status == 200) {
    //       this.restypedata = res.data;
    //     }
    //   });
    // },
    // 获取课程类型
    async querySubjectType() {
      await this.$Http.querySubjectType().then((res) => {
        // console.log(res, "获取课程类型");
        if (res.status == 200) {
          this.subjectypedata = res.data;
        }
      });
    },
    // 获取学科信息
    async querySubject(id) {
      // console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.querySubject({
        idList: ids,
      });
      if (status == 200) {
        // console.log(data, status, "获取学科");
        this.subjectdata = data;
      }
    },
    // 获取学段
    async queryPeriod() {
      await this.$Http.queryPeriod().then((res) => {
        // console.log(res, "获取学段");
        if (res.status == 200) {
          this.perioddata = res.data;
        }
      });
    },
    // 获取年级信息
    async queryGrade(id) {
      // console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: ids,
      });
      if (status == 200) {
        // console.log(data, status, "获取年级信息");
        this.gradedata = data;
      }
    },
    //审核状态
    async querystatus() {
      // console.log("获取审核状态");
      let aa = [
        {
          id: 0,
          name: "未提交审核",
          value: "0",
        },
        {
          id: 1,
          name: "审核未通过",
          value: "1",
        },
        {
          id: 2,
          name: "提交待审核",
          value: "2",
        },
        {
          id: 3,
          name: "审核已通过",
          value: "3",
        },
      ];
      this.auditdata = aa;
    },
    // 学科类型的值得变化
    changeshuject(val) {
      console.log(val, "学科类型", val, "学段", !val, typeof val);
      this.resourceForm.subjectId = null;
      if (!val) {
        this.querySubject(0);
      } else {
        this.querySubject(val);
      }
    },
    // 学段的值得变化
    changeperiod(val) {
      console.log(val, "学段", !val, typeof val);
      this.resourceForm.gradeId = null;
      if (!val) {
        this.queryGrade(0);
      } else {
        this.queryGrade(val);
      }
    },

    // 查询
    querylist() {
      this.queryCourselists();
    },
    // 重置
    resetfrom(formName) {
      // console.log(this.$refs, "重置");
      this.$refs[formName].resetFields();
      this.queryCourselists();
      this.querySubject(0); //获取学科信息
      this.queryGrade(0); //获取年级信息
    },

    // 提交
    async submitdata(rowId) {
      let ids = [];
      if (rowId == null) {
        if (this.multipleSelection == null) {
          this.$message.warning("请选择要批量提交的课程");
          return;
        } else {
          for (let x = 0; x < this.multipleSelection.length; x++) {
            ids.push(this.multipleSelection[x].id);
          }
        }
      } else {
        ids.push(rowId);
      }

      this.$confirm("是否确认提交审核", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data, status } = await this.$Http.classupdateAudit({
            courseId: ids,
          });
          if (status == 200) {
            this.$message({
              type: "success",
              message: "提交成功!",
            });
            // console.log(data, status, "课程提交审核");
            this.queryCourselists();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 删除
    async deldata(rowId) {
      let ids = [];
      if (rowId == null) {
        // console.log(this.multipleSelection, "this.multipleSelection ");
        if (this.multipleSelection == null) {
          this.$message.warning("请选择要批量删除的课程");
          return;
        } else {
          for (let x = 0; x < this.multipleSelection.length; x++) {
            ids.push(this.multipleSelection[x].id);
          }
        }
      } else {
        ids.push(rowId);
      }

      this.$confirm("是否确认删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data, status } = await this.$Http.deleteResource({
            courseId: ids,
          });
          if (status == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            // console.log(data, status, "课程删除");
            this.curpage = 1;
            this.queryCourselists();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 查看
    viewdata(row, num) {
      console.log(row, "查看", num);
      this.$router.push({
        path: "/introduce",
        query: {
          id: row.id,
          cardsite: num,
        },
      });
      return;
      let cutdata = JSON.stringify(row);
      if (row.auditStatus == "审核通过") {
        this.$router.push({
          path: "/introduce",
          query: {
            id: row.id,
          },
        });
      } else {
        this.$router.push({
          path: "/viewclass",
          query: {
            info: cutdata,
            bread: "我的课程",
            path: "/myaudit",
          },
        });
      }
    },
    // 编辑
    editdata(row) {
      // console.log(row);
      this.$router.push({
        path: "/upclass",
        query: { id: row.id },
      });
    },

    // 上传课程
    uploadresource() {
      // console.log(this.agreementDo, "上传课程");
      this.agreementDialog = true;
    },
    //同意协议
    sureagreement() {
      // console.log("同意协议");
      if (this.agreementDo) {
        this.$router.push({
          path: "/upclass",
        });
      } else {
        this.$message({
          message: "请先勾选协议",
          type: "warning",
        });
      }
    },
    // 取消协议
    cancleagreement() {
      this.agreementDialog = false;
      this.agreementDo = false;
      // console.log("取消协议");
    },
    //
    // 多选
    handleSelectionChange(val) {
      // console.log(val, "xuzhogn");
      this.multipleSelection = val;
    },
    //监听index
    computeTableIndex(index) {
      // console.log(index, this.queryInfo.pagenum, this.queryInfo.pagesize, 'e')
      return (this.curpage - 1) * this.pageSize + index + 1;
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.queryCourselists();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.curpage = val;
      this.queryCourselists();
    },
  },
  watch: {},
  computed: {
    resTabDatas() {
      let a = this.resTabData;
      if (a !== null) {
        let aa = this.resTabData.list;
        this.total = this.resTabData.total;
        if (aa[0].periodName.join === undefined) {
          return aa;
        }
        for (let x = 0; x < aa.length; x++) {
          aa[x].periodName = aa[x].periodName.join("/");
          aa[x].gradeName = aa[x].gradeName.join("/");
          aa[x].subjectName = aa[x].subjectName.join("/");
          aa[x].subjectType = aa[x].subjectType.join("/");
        }
        return aa;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.resource {
  .resource_center {
    header {
      display: flex;
      justify-content: space-between;
      // width: 1688px;
      height: 70px;
      background: #ffffff;
      padding: 0 20px;
      p {
        // width: 108px;
        height: 70px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        line-height: 70px;
      }
      .el-button {
        height: 30px;
        margin: 20px 0 0 0;
        .btns_icon {
          width: 13px;
          height: 15px;
          display: inline-block;
          background: url("../../../../assets/userinfo/upload.png") no-repeat;
          vertical-align: text-top;
        }
      }
    }
    nav {
      margin-top: 10px;
      background-color: #fff;
      padding: 15px;
      // .el-form-item{
      //   width: 230px;
      // }
      .el-input {
        width: 150px;
      }
      .el-select {
        width: 120px;
      }
      .nav_btns {
        display: flex;
        justify-content: flex-end;
      }
    }
    aside {
      background-color: #fff;
      margin-top: 12px;
      padding: 18px 20px;
    }
    main {
      background-color: #fff;
      padding: 0 20px;
      i {
        display: inline-block;
        width: 19px;
        height: 18px;
      }
      .btn_sumbit {
        background: url("../../../../assets/userinfo/submitbtn.png") no-repeat;
      }
      .btn_edit {
        background: url("../../../../assets/userinfo/editbtn.png") no-repeat;
      }
      .btn_view {
        background: url("../../../../assets/userinfo/viewbtn.png") no-repeat;
      }
      .btn_del {
        background: url("../../../../assets/userinfo/delbtn.png") no-repeat;
      }
      .el-pagination {
        display: flex;
        justify-content: center;
        padding: 20px 0;
      }
    }
    .agreeStyle {
      p {
        margin-top: 10px;
      }
    }
  }
}
</style>